.main-footer {
  color: white;
  font-size: medium;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}
.gmailogo {
  height: 13px;
  width: 20px;
}

@media (max-width: 480px) {
  .main-footer {
    color: white;
    font-size: medium;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
}
