.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 4;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.texto-hover {
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: #fff;
  margin-top: -20px;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.img-hover:hover .texto-hover {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .texto-hover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: visible;
    color: #fff;
    margin-top: -20px;
    opacity: 1;

    /* transition effect. not necessary */
  }

  .img-hover:hover .texto-hover {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
  .gallery .pics {
    width: 80%;
    margin-left: 40px;
  }
  .texto-hover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: visible;
    color: #fff;
    margin-top: -20px;
    opacity: 1;
    transition: auto;
    /* transition effect. not necessary */
  }

  .img-hover:hover .texto-hover {
    visibility: visible;
    opacity: 1;
  }
}
.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 1px;
}
.gallery .pics:hover {
  opacity: 0.4;
}
.closeNav {
  height: 10px;
}
/*/ */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale();
  overflow: hidden;
  z-index: 999;
}
.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 8rem;
  height: 8rem;
  padding: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
}
.photo-list {
  width: 90%;
  list-style: none;
  margin: 20px auto;
}

.photo-item {
  display: flex;
  width: 300px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  margin: 20px 0;
}

.photo-item img {
  display: flex;
  width: 100%;
  border: 4px double rgba(0, 0, 0, 0.12);
}
