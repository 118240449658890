.row {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 90px;
  grid-auto-rows: minmax(100px, auto);
  margin-left: 21%;
  margin-right: 22%;
  margin-top: 6%;
}

.cont {
  position: relative;
  text-align: center;
  color: white;
}
.imageS {
  height: 400px;
  width: 350px;
}
.cont:hover {
  opacity: 0.5;
  color: white;
}
.goBack {
  display: none;
}

.centered {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 30px;
  color: #fff;
  margin-top: -20px;
  opacity: 0;
  color: white;

  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.img-serv:hover .centered {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 991px) {
  .centered {
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    visibility: visible;
    color: #fff;
    margin-top: 0px;
    opacity: 1;
    position: relative;

    /* transition effect. not necessary */
  }

  .img-serv:hover .centered {
    visibility: visible;
    opacity: 1;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    margin-left: 14%;
  }
  .imageS {
    height: 350px;
    width: 290px;
  }
  .goBack {
    position: fixed;
    margin-top: 10px;
    margin-left: 1px;
    height: 50px;
    display: block;
  }
}

@media (max-width: 480px) {
  .centered {
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    visibility: visible;
    color: #fff;
    margin-top: -20px;
    opacity: 1;

    /* transition effect. not necessary */
  }

  .img-serv:hover .centered {
    visibility: visible;
    opacity: 1;
  }
  .row {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    margin-left: 14%;
  }
  .imageS {
    height: 350px;
    width: 290px;
  }
  .goBack {
    position: fixed;
    margin-top: 10px;
    margin-left: 1px;
    height: 50px;
    display: block;
  }
}
