.experiencias-page {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 500px 500px 500px;
  row-gap: 60px;
  margin-left: 50px;
  margin-bottom: 10px;
  font-family: "Belgrano";
}
.container-exp-page {
  width: 10cm;
}
@media (max-width: 991px) {
  .experiencias-page {
    margin-top: 100px;
    display: block;

    margin-left: 10px;
    margin-bottom: 10px;
  }
  .container-exp-page {
    width: 9cm;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
}
