.experiencias {
  margin-top: 120px;
}

.block-experiencias {
  display: flex;
  text-align: center;
}

.title-exp {
  font-size: 5rem;
}
.container-exp {
  margin-left: 30px;
  margin-top: 20px;
  width: 15cm;
  height: 200px;
}

.text-exp {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 1.5em;
  color: white;
}

.name-exp {
  margin-top: 95px;
  margin-left: 10px;
  text-align: left;
  font-size: medium;
}
.verMas {
  text-align: right;
  margin-top: -15px;
}

.contact-inicio {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 1280px;
}

@media (max-width: 480px) {
  .hero {
    margin-top: -100px;
  }
  .experiencias {
    margin-bottom: -200px;
  }
  .block-experiencias {
    display: block;
    margin-bottom: -300px;
  }
  .container-exp {
    margin-left: auto;
    margin-top: 20px;
    width: 14cm;
    height: 190px;
  }
  .text-exp {
    margin-left: auto;
    margin-top: 9px;
    font-size: 1.8em;
    text-align: justify;
  }
  .name-exp {
    margin-top: 20px;
    margin-left: auto;
    text-align: left;
    font-size: medium;
  }
  .seeMore {
    margin-top: 300px;
  }
  .contact-inicio {
    margin-top: 1000px;
    display: block;
    justify-content: space-around;
    width: auto;
  }
}

@media (max-width: 991px) {
  /*este es para tablet */

  .block-experiencias {
    display: block;
    text-align: center;
  }
  .container-exp {
    margin-left: 0px;
    margin-top: 20px;
    width: auto;
    height: 190px;
  }
  .name-exp {
    margin-top: 20px;
    margin-left: 10px;
    text-align: left;
    font-size: medium;
  }
  .contact-inicio {
    margin-top: 250px;
    display: block;
    justify-content: space-around;
    width: auto;
  }
}
